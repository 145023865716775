import "@mantine/core/styles.css";

import React, { useState } from "react";
import axios from "axios";
import {
  MantineProvider,
  Container,
  TextInput,
  Button,
  Select,
  Space,
  Title,
} from "@mantine/core";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";

const App = () => {
  const [section, setSection] = useState("featured_news");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(""); // State to hold editor content

  // Initialize the editor
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: "<h1>write here</h1>", // Initial content
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML()); // Update content state whenever the editor's content changes
    },
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://koiconnect-server-f3e8.onrender.com/home/add-article",
        {
          section,
          title,
          content, // Send the content from the editor
        }
      );
      alert(`Article added with ID: ${response.data.id}`);
      setTitle("");
      setContent("");
      editor.commands.clearContent(); // Clear the editor content after submission
    } catch (error) {
      alert("Error adding article: " + error.message);
    }
  };

  return (
    <MantineProvider
      theme={{
        colorScheme: "light", // Switch to 'dark' if needed
        primaryColor: "blue", // Customize your primary color
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Container size="md" my="xl">
        <Title align="center" mb="lg">
          Add New Article
        </Title>
        <form onSubmit={handleSubmit}>
          <Select
            label="Section"
            value={section}
            onChange={setSection}
            data={[
              { value: "featured_news", label: "Featured News" },
              { value: "latest_articles", label: "Latest Articles" },
              { value: "popular_articles", label: "Popular Articles" },
              { value: "trending_topics", label: "Trending Topics" },
              { value: "upcoming_events", label: "Upcoming Events" },
              { value: "industry_news", label: "Industry News" },
              { value: "anime_announcements", label: "Anime Announcements" },
              { value: "manga_releases", label: "Manga Releases" },
              { value: "conventions_events", label: "Conventions & Events" },
              { value: "streaming_updates", label: "Streaming Updates" },
              { value: "studio_updates", label: "Studio Updates" },
              { value: "anime_reviews", label: "Anime Reviews" },
              { value: "manga_reviews", label: "Manga Reviews" },
              { value: "episode_reviews", label: "Episode Reviews" },
              { value: "movie_reviews", label: "Movie Reviews" },
              {
                value: "rating_system_explanation",
                label: "Rating System Explanation",
              },
              { value: "upcoming_anime", label: "Upcoming Anime" },
              { value: "upcoming_manga", label: "Upcoming Manga" },
              { value: "trailers", label: "Trailers" },
              { value: "first_impressions", label: "First Impressions" },
              { value: "editorials", label: "Editorials" },
              { value: "interviews", label: "Interviews" },
              { value: "opinion_pieces", label: "Opinion Pieces" },
              { value: "top_lists", label: "Top Lists" },
              { value: "in_depth_analysis", label: "In-depth Analysis" },
              { value: "watch_guides", label: "Watch Guides" },
              { value: "reading_guides", label: "Reading Guides" },
              { value: "character_guides", label: "Character Guides" },
              { value: "franchise_overviews", label: "Franchise Overviews" },
              { value: "merchandise_guides", label: "Merchandise Guides" },
              { value: "reviews_reactions", label: "Reviews & Reactions" },
              { value: "convention_coverage", label: "Convention Coverage" },
              { value: "tutorials", label: "Tutorials" },
              { value: "about_us", label: "About Us" },
              { value: "staff_bios", label: "Staff Bios" },
              { value: "contact_information", label: "Contact Information" },
              { value: "search_index", label: "Search Index" },
            ]}
          />
          <Space h="md" />

          <TextInput
            label="Title"
            withAsterisk
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            required
          />
          <Space h="md" />

          <RichTextEditor editor={editor} required>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Undo />
                <RichTextEditor.Redo />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>

          <Space h="md" />
          <Button type="submit" fullWidth>
            Add Article
          </Button>
        </form>
      </Container>
    </MantineProvider>
  );
};

export default App;
